/* You can add global styles to this file, and also import other style files */

@import "/src/vars.scss";

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background-color: #eeeeee;
}

.ngx-bottom-nav-button {
  --ngx-bottom-nav-button-color: #77797d;
}

.ngx-bottom-nav-button.active {
  --ngx-bottom-nav-button-active-color: #8e3156;
}

ngx-bottom-nav {
  mat-toolbar {
    overflow: visible !important;
  }

  .mat-badge-content {
    z-index: 1 !important;
  }
}

.extended-fab-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 56px;
  text-align: center;
  z-index: 1;

  a,
  button {
    margin: 32px 0;
    padding-left: 8px;
    border-radius: 24px;
    text-transform: uppercase;
  }

  mat-icon {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.extended-fab-spacer {
  display: block;
  height: 100px;
}

.mat-tab-label.mat-tab-label-active {
  opacity: 1;

  .mat-tab-label-content {
    color: #8e3156;
    font-weight: bold;
  }
}

.mat-snack-bar-container {

  margin: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  @media screen and (max-width: 960px) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

  }

  button {
    color: #bdd448;
  }

}

.mat-input-element {
  font-size: 1.25em !important;
}

.input-full-width {
  width: 100%;
}

.container-with-padding {
  padding: 24px;
}

.container-max-width {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.mat-tab-link.mat-tab-label-active {
  color: #8e3156;
  opacity: 1;
}

.mat-dialog-actions {
  margin-bottom: -12px !important;
}


.cropper-container {
  border: 32px solid grey;

  @media screen and (max-width: 960px) {
    border: 0;
  }
}

.cropper img {
  max-height: 50vh !important;
}

.link-unstyled {
  color: inherit;
  text-decoration: inherit;
}

button[matchipremove] {
  border: 0;
  background: transparent;

  mat-icon {
    position: relative;
    left: -10px;
    top: -4px;
  }
}

.mat-chip-list-inventario .mat-chip-list-wrapper {
  flex-wrap: nowrap !important;
  margin: 0 !important;
}

.mat-badge-content {
  color: white;
  background: $esploravinoRed;
}